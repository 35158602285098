@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// colors
$tiaMaria: #D3220B;
$botticelli: #D2DEEA;
$polar: #F6FBFD;
$black: #000000;

$theme-colors: (
  "tia-maria": $tiaMaria,
  "botticelli": $botticelli,
  "polar": $polar,
);

// grid
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1800px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1680px
);

$grid-gutter-width: 1.875rem;

$spacer: 1rem !default;
$spacers: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        6: $spacer * 5,
        7: $spacer * 8,
        8: $spacer * 13,
        9: $spacer * 21,
);

$enable-negative-margins: true;

// rfs
$rfs-base-value: 0.8rem;
$rfs-factor: 3;

$font-family-sans-serif: 'Roboto';

$font-size-base: 1.0625rem;

$h1-font-size: $font-size-base * 5.294; //90px
$h2-font-size: $font-size-base * 2.647; //45px
$h3-font-size: $font-size-base * 1.294; //22px
$h4-font-size: $font-size-base; //17px
$h5-font-size: $font-size-base * 0.882; //15px
$h6-font-size: $font-size-base * 0.705; //12px

$headings-font-weight: 400;

// accordion
$accordion-button-active-bg: white;
$accordion-border-color: $black;
$accordion-button-focus-box-shadow: none;
$accordion-button-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'%3E%3Ctitle%3EAdd%3C/title%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M256 112v288M400 256H112'/%3E%3C/svg%3E%0A");
$accordion-button-active-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'%3E%3Ctitle%3ERemove%3C/title%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M400 256H112'/%3E%3C/svg%3E%0A");

// nav-link
$nav-link-font-size: $h5-font-size;
$navbar-light-color: $black;
$navbar-light-hover-color: $tiaMaria;
$navbar-light-active-color:  $tiaMaria;
$navbar-light-disabled-color: rgba($black, .3);

@import '~bootstrap';
@import './src/components/atoms/HamburgerIcon';

//$theme-colors: map-merge($theme-colors, $custom-colors);

body {
  height: 100vh !important;
}

.ff-lora {
  font-family: 'Lora';
}

.ls-n1 {
  letter-spacing: -1px;
}

.z-index-100 {
  z-index: 100;
}


.accordion-item {
  border-bottom: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color) !important;

  &:first-of-type {
    border-top: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color) !important;
  }
}

.accordion-button {
  &:not(.collapsed) {
    box-shadow: none;
    background-color: transparent;
  }
  &:after {
    margin-left: -250px;
    margin-right: 250px;
  }
}

.signature {
  display: inline-block;
  width: 50px;
}

.modal-body {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 44.43 61.17'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:white;stroke:white;stroke-miterlimit:10;stroke-width:.5px;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M.25,1.51V.45l.09,.06v-.03H44.06l-.32,.82c-.01,.08-6.57,16.48-29.29,21.27h0c.48,2.5,.76,5.24,.76,8.24h0c0,21.1-13.98,29.37-14.06,29.41h0l-.9,.53V1.51H.25ZM1.44,58.57c.32-.23,.72-.54,1.17-.92h0c1.26-1.06,2.95-2.69,4.64-4.96h0c3.38-4.54,6.77-11.62,6.77-21.89h0c0-2.91-.27-5.57-.74-8h0c-3.56,.67-7.49,1.06-11.84,1.07h0V58.57h0Zm.83-35.9c3.94-.06,7.52-.43,10.77-1.04h0c-.44-1.94-.99-3.72-1.63-5.34h0c-3.78,1.93-6.78,4.18-9.13,6.38h0Zm-.74-.95c2.43-2.26,5.53-4.57,9.41-6.54h0C8.4,9.29,4.84,5.55,2.61,3.61h0c-.41-.36-.77-.65-1.07-.88h0V21.73h0Zm12.67-.33c9.87-2.08,16.57-6.38,20.98-10.51h0c-9.55,.13-16.98,2.13-22.72,4.87h0c.68,1.72,1.27,3.59,1.73,5.64h0Zm-2.19-6.74c6.09-2.9,14.01-4.97,24.16-4.97h0c.07,0,.15,0,.23,0h0c1.96-2.02,3.39-3.93,4.38-5.46h0c.7-1.08,1.17-1.96,1.46-2.55H2.11c2,1.51,6.71,5.65,9.91,12.98h0Z'/%3E%3C/svg%3E%0A");
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: -50px -50px;
}

.spinning {
  animation-name: spin;
  animation-duration: 30000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
